.section {
  margin: 3rem 0 6rem;
}

.title {
  margin: 1.5rem 0;
  font-size: 1.5rem;
}

.title::before {
  display: block;
  width: 3rem;
  height: 0.25rem;
  margin: 1rem 0;
  background-color: var(--color-primary-400);
  border-radius: 2px;
  content: '';
}
