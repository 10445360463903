.list {
  display: inline-grid;
  margin: 0;
  gap: 2rem;
  grid-template-columns: auto auto;
}

.align-stretch {
  align-items: stretch;
}

.align-start {
  align-items: start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: end;
}
