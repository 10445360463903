@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'),
    url('/fonts/SourceSansPro-Light.woff2') format('woff2'),
    url('/fonts/SourceSansPro-Light.woff') format('woff');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: local('Source Sans Pro Light Italic'), local('SourceSansPro-LightItalic'),
    url('/fonts/SourceSansPro-LightIt.woff2') format('woff2'),
    url('/fonts/SourceSansPro-LightIt.woff') format('woff');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
    url('/fonts/SourceSansPro-Regular.woff2') format('woff2'),
    url('/fonts/SourceSansPro-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: local('Source Sans Pro Italic'), local('SourceSansPro-Italic'),
    url('/fonts/SourceSansPro-It.woff2') format('woff2'),
    url('/fonts/SourceSansPro-It.woff') format('woff');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: local('Source Sans Pro SemiBold'), local('SourceSansPro-SemiBold'),
    url('/fonts/SourceSansPro-Semibold.woff2') format('woff2'),
    url('/fonts/SourceSansPro-Semibold.woff') format('woff');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: local('Source Sans Pro SemiBold Italic'),
    local('SourceSansPro-SemiBoldItalic'),
    url('/fonts/SourceSansPro-SemiboldIt.woff2') format('woff2'),
    url('/fonts/SourceSansPro-SemiboldIt.woff') format('woff');
}

html {
  font-size: 16px;
}

p,
li {
  font-size: 1.25rem;
  line-height: 1.6;
}

p {
  margin: 1em 0;
}

li {
  margin-bottom: 1em;
}
