.container {
  margin: 1rem 0 2rem;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: var(--elevation-2);
}

.title {
  padding: 1rem 1rem 0;
  margin: 0;
  color: var(--color-neutral-300);
  font-size: 1rem;
}

.stage {
  padding: 1rem;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.pre {
  padding: 1rem !important;
  margin: 0 !important;
  background-color: var(--color-neutral-1100) !important;
  border-bottom-left-radius: inherit !important;
  border-bottom-right-radius: inherit !important;
}

.code {
  display: block;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier,
    monospace;
  font-size: 0.8125rem;
  line-height: 1.7;
}
