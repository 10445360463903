.link {
  position: relative;
  color: var(--color-primary-400);
  font-weight: 600;
  text-decoration: none;
}

.link::after {
  position: absolute;
  z-index: -1;
  top: -3px;
  right: -4px;
  bottom: -3px;
  left: -4px;
  border-radius: 4px;
  content: '';
  transition-duration: 0.25s;
  transition-property: background-color;
  transition-timing-function: ease-out;
}

.link:hover::after {
  background-color: hsl(0, 0%, 95%);
}
