.input {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border: 2px solid var(--color-neutral-700);
  background-color: #fff;
  border-radius: 4px;
  color: var(--color-body-text);
  font: 1rem var(--font-body);
  transition-duration: 0.25s;
  transition-property: border-color;
  transition-timing-function: ease-out;
}

.input:disabled {
  background-color: var(--color-neutral-900);
}

.input:focus {
  outline: 0;
}

.input:not(:disabled):not(:read-only):hover {
  border-color: var(--color-neutral-500);
}

.input:not(:disabled):not(:read-only):focus {
  background-color: transparent;
  border-color: var(--color-primary-400);
}

.single-line {
  height: 2.25rem;
  padding-top: 0;
  padding-bottom: 0;
}

.multiline {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.4;
}

.align-start {
  text-align: start;
}

.align-center {
  text-align: center;
}

.align-end {
  text-align: end;
}
