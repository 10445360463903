.link {
  display: inline-flex;
  flex-shrink: 0;
  padding: 0.625rem 0.75rem;
  border-radius: 50px;
  color: hsl(0, 0%, 35%);
  font-size: 1rem;
  line-height: 1;
  text-decoration: none;
  transition: box-shadow 0.25s ease-out, color 0.25s ease-out,
    background-color 0.25s ease-out, margin 0.25s ease-out;
  user-select: none;
}

.link:not(.active):hover {
  color: #000;
}

.active {
  padding-right: 1rem;
  padding-left: 1rem;
  margin: 0 0.5rem;
  background-color: var(--color-primary-700);
  color: var(--color-primary-200);
  composes: link;
  font-weight: 600;
}
