.swatch {
  display: flex;
  width: 500px;
  height: 100px;
}

.color {
  flex: 1 0 0%;
}

.colorName {
  margin: 1.5rem 0 0.5rem;
  font-weight: 600;
}
