.th {
  padding: 0 0 0.5rem;
  border-bottom: 1px solid hsl(0, 0%, 80%);
  color: hsl(0, 0%, 35%);
  font-size: 0.8125em;
  letter-spacing: 0.05em;
  text-align: start;
  text-transform: uppercase;
}

.th:not(:first-child) {
  padding-left: 1rem;
}

.th:not(:last-child) {
  padding-right: 1rem;
}
