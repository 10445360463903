.small {
  width: 0.5rem;
  height: 0.5rem;
}

.medium {
  width: 1rem;
  height: 1rem;
}

.large {
  width: 2rem;
  height: 2rem;
}

.row {
  display: inline-block;
  height: auto;
}

.column {
  display: block;
  width: auto;
}
