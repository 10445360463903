.container {
  position: sticky;
  z-index: var(--z-index-nav);
  top: 0;
  display: flex;
  height: 3rem;
  align-items: center;
  padding: 0 1rem;
  border-bottom: 1px solid hsl(0, 0%, 85%);
  background-color: hsla(0, 0%, 100%, 0.955);
}
