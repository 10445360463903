.container {
  position: relative;
  display: inline-flex;
}

.select {
  padding: 0.5rem;
  border: 2px solid var(--color-neutral-700);
  appearance: none;
  background-color: #fff;
  border-radius: 4px;
  color: var(--color-body-text);
  font: 1rem var(--font-body);
  transition-duration: 0.25s;
  transition-property: border-color;
  transition-timing-function: ease-out;
}

.select:not([multiple]) {
  padding-right: 1.75rem;
}

.select:disabled {
  background-color: var(--color-neutral-900);
}

.select:not(:disabled):hover {
  border-color: var(--color-neutral-500);
}

.select:not(:disabled):focus {
  border-color: var(--color-primary-400);
  outline: 0;
}

.arrow {
  position: absolute;
  top: 50%;
  right: 0.375rem;
  width: 0.875rem;
  margin-top: 1px;
  fill: var(--color-body-text);
  transform: translateY(-50%);
}

.select[multiple] + .arrow {
  display: none;
}
