.label {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  cursor: pointer;
}

.label.disabled {
  cursor: default;
}

.contained {
  padding: 0.5rem;
  background-color: var(--color-neutral-1000);
  border-radius: 4px;
}

.contained:hover {
  background-color: var(--color-neutral-900);
}

.contained:active {
  background-color: var(--color-neutral-800);
}

.input {
  position: absolute;
  z-index: -1;
  margin: 0;
  opacity: 0;
}

.box {
  display: flex;
  width: 1.125rem;
  height: 1.125rem;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--color-neutral-600);
  border-radius: 4px;
  transition-duration: 0.25s;
  transition-property: background-color, border-color;
  transition-timing-function: ease-out;
}

.input:checked + .box,
.input:indeterminate + .box {
  background-color: var(--color-primary-400);
  border-color: var(--color-primary-400);
}

.input:disabled:checked + .box,
.input:disabled:indeterminate + .box {
  background-color: var(--color-neutral-600);
  border-color: var(--color-neutral-600);
}

.label:focus-within [data-focus-visible-added] + .box {
  outline: var(--focus);
}

.label:hover .input:not(:disabled) + .box {
  background-color: var(--color-primary-700);
  border-color: var(--color-primary-600);
}

.label:active .input:not(:disabled) + .box {
  background-color: var(--color-primary-600);
  border-color: var(--color-primary-500);
}

.input:indeterminate + .box::before {
  position: absolute;
  width: 0.75rem;
  height: 0.125rem;
  background-color: #fff;
  border-radius: 2px;
  content: '';
}

.label:hover .input:not(:disabled):checked + .box,
.label:hover .input:not(:disabled):indeterminate + .box {
  background-color: var(--color-primary-500);
  border-color: var(--color-primary-500);
}

.label:active .input:not(:disabled):checked + .box,
.label:active .input:not(:disabled):indeterminate + .box {
  background-color: var(--color-primary-300);
  border-color: var(--color-primary-300);
}

.icon {
  flex: 1 0 auto;
  color: #fff;
}

.input:not(:checked) + .box .icon {
  display: none;
}

.text {
  margin-left: 0.5rem;
}
