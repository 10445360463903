.label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.contained {
  padding: 0.5rem;
  background-color: var(--color-neutral-1000);
  border-radius: 4px;
}

.contained:hover {
  background-color: var(--color-neutral-900);
}

.contained:active {
  background-color: var(--color-neutral-800);
}

.input {
  position: absolute;
  z-index: -1;
  margin: 0;
  opacity: 0;
}

.circle {
  display: flex;
  width: 1.125rem;
  height: 1.125rem;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border: 2px solid var(--color-neutral-600);
  border-radius: 50%;
  transition-duration: 0.25s;
  transition-property: background-color, border-color;
  transition-timing-function: ease-out;
}

.label:focus-within [data-focus-visible-added] + .circle {
  outline: var(--focus);
}

.input:not(:disabled):checked + .circle {
  border-color: var(--color-primary-400);
}

.label:hover .input:not(:disabled):not(:checked) + .circle {
  background-color: var(--color-primary-700);
  border-color: var(--color-primary-600);
}

.label:active .input:not(:disabled):not(:checked) + .circle {
  background-color: var(--color-primary-600);
  border-color: var(--color-primary-500);
}

.label:hover .input:not(:disabled):checked + .circle {
  border-color: var(--color-primary-300);
}

.label:active .input:not(:disabled):checked + .circle {
  border-color: var(--color-primary-200);
}

.input:checked + .circle::after {
  flex: 1 0 0%;
  align-self: stretch;
  background-color: var(--color-primary-400);
  border-radius: 50%;
  content: '';
  transition-duration: 0.25s;
  transition-property: background-color;
  transition-timing-function: ease-out;
}

.input:checked:hover + .circle::after {
  background-color: var(--color-primary-300);
}

.input:checked:active + .circle::after {
  background-color: var(--color-primary-200);
}

.input:disabled:checked + .circle::after {
  background-color: var(--color-neutral-600);
}

.text {
  margin-left: 0.5rem;
}
