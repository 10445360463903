.td {
  padding: 0.75rem 0;
  border-bottom: 1px solid hsl(0, 0%, 80%);
}

.td:not(:first-child) {
  padding-left: 1rem;
}

.td:not(:last-child) {
  padding-right: 1rem;
}
