.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem 0;
}

.label {
  margin-bottom: 0.375rem;
}
