*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --color-primary: hsl(210, 100%, 45%);
  --color-primary-d1: hsl(210, 100%, 40%);
  --color-primary-d2: hsl(210, 100%, 35%);
  --color-primary-pastel: hsl(210, 85%, 93%);
  --color-gray-light: hsl(0, 0%, 95%);
  --color-gray-light-d1: hsl(0, 0%, 90%);
  --color-gray-light-d2: hsl(0, 0%, 85%);
  --color-gray-light-d3: hsl(0, 0%, 80%);
  --color-body-text: hsl(0, 0%, 20%);
  --font-body: 'Source Sans Pro', sans-serif;

  /* Primary */
  --hue-primary: 210;
  --color-primary-100: hsl(210, 100%, 18%);
  --color-primary-200: hsl(210, 100%, 25%);
  --color-primary-300: hsl(210, 100%, 35%);
  --color-primary-400: hsl(210, 100%, 45%);
  --color-primary-500: hsl(210, 100%, 55%);
  --color-primary-600: hsl(210, 100%, 75%);
  --color-primary-700: hsl(210, 100%, 93%);

  /* Success */
  --hue-success: 160;
  --color-success-100: hsl(160, 70%, 13%);
  --color-success-200: hsl(160, 70%, 18%);
  --color-success-300: hsl(160, 70%, 27%);
  --color-success-400: hsl(160, 60%, 40%);
  --color-success-500: hsl(160, 60%, 50%);
  --color-success-600: hsl(160, 60%, 70%);
  --color-success-700: hsl(160, 60%, 90%);

  /* Warning */
  --hue-warning: 45;
  --color-warning-100: hsl(45, 75%, 18%);
  --color-warning-200: hsl(45, 75%, 23%);
  --color-warning-300: hsl(45, 75%, 35%);
  --color-warning-400: hsl(45, 75%, 48%);
  --color-warning-500: hsl(45, 75%, 62%);
  --color-warning-600: hsl(45, 85%, 75%);
  --color-warning-700: hsl(45, 95%, 93%);

  /* Error */
  --hue-error: 355;
  --color-error-100: hsl(355, 75%, 20%);
  --color-error-200: hsl(355, 75%, 27%);
  --color-error-300: hsl(355, 75%, 40%);
  --color-error-400: hsl(355, 75%, 55%);
  --color-error-500: hsl(355, 75%, 68%);
  --color-error-600: hsl(355, 85%, 80%);
  --color-error-700: hsl(355, 95%, 93%);

  /* Info */
  --hue-info: 190;
  --color-info-100: hsl(190, 45%, 15%);
  --color-info-200: hsl(190, 45%, 25%);
  --color-info-300: hsl(190, 45%, 40%);
  --color-info-400: hsl(190, 45%, 50%);
  --color-info-500: hsl(190, 45%, 64%);
  --color-info-600: hsl(190, 45%, 79%);
  --color-info-700: hsl(190, 45%, 94%);

  /* Neutral */
  --color-neutral-100: hsl(210, 20%, 20%);
  --color-neutral-200: hsl(210, 20%, 30%);
  --color-neutral-300: hsl(210, 20%, 40%);
  --color-neutral-400: hsl(210, 20%, 50%);
  --color-neutral-500: hsl(210, 20%, 65%);
  --color-neutral-600: hsl(210, 20%, 75%);
  --color-neutral-700: hsl(210, 20%, 80%);
  --color-neutral-800: hsl(210, 20%, 85%);
  --color-neutral-900: hsl(210, 20%, 91%);
  --color-neutral-1000: hsl(210, 20%, 94%);
  --color-neutral-1100: hsl(210, 20%, 97%);

  /* Elevation */
  --elevation-1: 0 1px 3px hsla(0, 0%, 0%, 0.1),
    0 1px 2px 1px hsla(0, 0%, 0%, 0.15);
  --elevation-2: 0 3px 6px hsla(0, 0%, 0%, 0.15),
    0 2px 4px 1px hsla(0, 0%, 0%, 0.12);
  --elevation-3: 0 10px 20px hsla(0, 0%, 0%, 0.15),
    0 3px 6px 2px hsla(0, 0%, 0%, 0.1);
  --elevation-4: 0 15px 30px hsla(0, 0%, 0%, 0.14),
    0 5px 15px 1px hsla(0, 0%, 0%, 0.18);
  --elevation-5: 0 20px 40px 2px hsla(0, 0%, 0%, 0.25),
    0 1px 3px hsla(0, 0%, 0%, 0.05);

  /* Z-Index Levels */
  --z-index-nav: 500;

  /* Global Focus State */
  --focus: 2px solid hsla(var(--hue-primary), 100%, 45%, 0.75);
}

body {
  padding: 0;
  margin: 0;
  color: var(--color-body-text);
  font-family: var(--font-body);
}

:focus {
  outline: var(--focus);
}

:focus:not([data-focus-visible-added]) {
  outline: 0;
}

::selection {
  background-color: var(--color-primary-600);
}
