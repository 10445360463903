.button {
  display: inline-flex;
  align-items: center;
  padding: 0;
  border: 0;
  border-radius: 50px;
  cursor: pointer;
  font: 1rem var(--font-body);
  font-weight: 600;
  transition-duration: 0.25s;
  transition-property: box-shadow, background-color, color, transform;
  transition-timing-function: ease-out;
  user-select: none;
}

.primary,
.secondary,
.tertiary {
  height: 2.25rem;
}

.primary,
.secondary {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}

.primary {
  background-color: var(--color-primary-400);
  box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.35),
    0 2px 4px 1px rgba(60, 64, 67, 0.2);
  color: #fff;
}

.secondary {
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.25),
    0 2px 4px 1px rgba(60, 64, 67, 0.14);
  box-shadow: var(--elevation-1);
  color: hsl(0, 0%, 35%);
}

.primary:hover,
.secondary:hover {
  transform: translateY(-1px);
}

.primary:hover {
  box-shadow: 0 1px 4px 0 rgba(60, 64, 67, 0.35),
    0 4px 10px 3px rgba(60, 64, 67, 0.25);
}

.primary:active {
  background-color: var(--color-primary-300);
}

.secondary:hover {
  box-shadow: 0 1px 4px 0 rgba(60, 64, 67, 0.28),
    0 4px 10px 3px rgba(60, 64, 67, 0.16);
  box-shadow: var(--elevation-2);
}

.secondary:active {
  background-color: var(--color-gray-light);
}

.tertiary,
.icon {
  background-color: transparent;
  color: hsl(0, 0%, 35%);
}

.icon {
  padding: 0.375rem;
}

.tertiary {
  padding-right: 1rem;
  padding-left: 1rem;
}

.tertiary:hover,
.icon:hover {
  color: hsl(0, 0%, 15%);
}

.tertiary:active,
.icon:active {
  background-color: var(--color-gray-light);
}
