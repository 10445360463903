.icon {
  display: inline-flex;
  align-self: center;
  justify-self: center;
}

.small {
  width: 1rem;
}

.medium {
  width: 1.5rem;
}

.large {
  width: 2rem;
}
